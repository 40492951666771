// Firebase
const firebaseConfig = {
  apiKey: 'AIzaSyDcHaeBdWXDxhNHg1TJxggn1GRV15WyRbc',
  authDomain: 'ionata-ping-pong.firebaseapp.com',
  projectId: 'ionata-ping-pong',
  storageBucket: 'ionata-ping-pong.appspot.com',
  messagingSenderId: '393602133708',
  appId: '1:393602133708:web:9b045f423fb8396a249c69',
};

// Initialize firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

let db = firebase.firestore();

// Game Logic
const canvas = document.getElementById('game-canvas');
const ctx = canvas.getContext('2d');

const NET_WIDTH = 2;
const NET_HEIGHT = canvas.height;

const PADDLE_WIDTH = 4;
const PADDLE_HEIGHT = 70;

let KEY_UP = false;
let KEY_DOWN = false;
let isPaused = true;

// Net
const net = {
  x: canvas.width / 2 - NET_WIDTH / 2,
  y: 0,
  width: NET_WIDTH,
  height: NET_HEIGHT,
  color: '#FFF',
};

// User Paddle
const user = {
  x: 10,
  y: canvas.height / 2 - PADDLE_HEIGHT / 2,
  width: PADDLE_WIDTH,
  height: PADDLE_HEIGHT,
  color: '#FFF',
  score: 0,
};

// AI Paddle
const ai = {
  x: canvas.width - (PADDLE_WIDTH + 10),
  y: canvas.height / 2 - PADDLE_HEIGHT / 2,
  width: PADDLE_WIDTH,
  height: PADDLE_HEIGHT,
  color: '#FFF',
  score: 0,
};

// Ball
const ball = {
  x: canvas.width / 2,
  y: canvas.height / 2,
  radius: 7,
  speed: 4,
  velocityX: 5,
  velocityY: 5,
  color: '#bfcedd',
};

// Draw Functions
function drawNet() {
  ctx.fillStyle = net.color;
  ctx.fillRect(net.x, net.y, net.width, net.height);
}

function drawScore(x, y, score) {
  ctx.fillStyle = '#fff';
  ctx.font = '20px Exo';

  ctx.fillText(score, x, y);
}

function drawPaddle(x, y, width, height, color) {
  ctx.fillStyle = color;
  ctx.fillRect(x, y, width, height);
}

function drawBall(x, y, radius, color) {
  ctx.fillStyle = color;
  ctx.beginPath();
  ctx.arc(x, y, radius, 0, Math.PI * 2, true);
  ctx.closePath();
  ctx.fill();
}

// Paddle Movements
window.addEventListener('keyup', keyUpHandler);
window.addEventListener('keydown', keyDownHandler);

function keyUpHandler(event) {
  switch (event.keyCode) {
    case 38:
      KEY_UP = false;
      break;
    case 40:
      KEY_DOWN = false;
      break;
  }
}

function keyDownHandler(event) {
  switch (event.keyCode) {
    case 38:
      KEY_UP = true;
      break;
    case 40:
      KEY_DOWN = true;
      break;
  }
}

function reset() {
  ball.x = canvas.width / 2;
  ball.y = canvas.height / 2;
  ball.speed = 7;

  ball.velocityX = -ball.velocityX;
  ball.velocityY = -ball.velocityY;
}

function collisionDetect(player, ball) {
  player.top = player.y;
  player.right = player.x + player.width;
  player.bottom = player.y + player.height;
  player.left = player.x;

  ball.top = ball.y - ball.radius;
  ball.right = ball.x + ball.radius;
  ball.bottom = ball.y + ball.radius;
  ball.left = ball.x - ball.radius;

  return (
    ball.left < player.right &&
    ball.top < player.bottom &&
    ball.right > player.left &&
    ball.bottom > player.top
  );
}

function update() {
  if (KEY_UP && user.y > 0) {
    user.y -= 8;
  } else if (KEY_DOWN && user.y < canvas.height - user.height) {
    user.y += 8;
  }

  if (ball.y + ball.radius >= canvas.height || ball.y - ball.radius <= 0) {
    ball.velocityY = -ball.velocityY;
  }

  // If ball hit on right wall
  if (ball.x + ball.radius >= canvas.width) {
    // then user scored 1 point
    user.score += 1;
    reset();
  }

  // If ball hit on left wall
  if (ball.x - ball.radius <= 0) {
    // then ai scored 1 point
    ai.score += 1;
    reset();
  }

  // move the ball
  ball.x += ball.velocityX;
  ball.y += ball.velocityY;

  // ai paddle movement
  ai.y += (ball.y - (ai.y + ai.height / 2)) * 0.09;

  // collision detection on paddles
  let player = ball.x < canvas.width / 2 ? user : ai;

  if (collisionDetect(player, ball)) {
    // default angle is 0deg in Radian
    let angle = 0;

    // if ball hit the top of paddle
    if (ball.y < player.y + player.height / 2) {
      // then -1 * Math.PI / 4 = -45deg
      angle = (-1 * Math.PI) / 4;
    } else if (ball.y > player.y + player.height / 2) {
      // if it hit the bottom of paddle
      // then angle will be Math.PI / 4 = 45deg
      angle = Math.PI / 4;
    }

    /* change velocity of ball according to on which paddle the ball hitted */
    ball.velocityX = (player === user ? 1 : -1) * ball.speed * Math.cos(angle);
    ball.velocityY = ball.speed * Math.sin(angle);

    // increase ball speed
    ball.speed += 0.2;
  }

  if (ai.score == 10) {
    // End the game
    clearInterval(interval);

    // Publish score
    if (popup.classList.contains('home-submit')) {
      popup.classList.add('home-submit--is-visible');
      score.innerHTML = `${user.score}`;
    }
  }
}

function render() {
  ctx.fillStyle = '#343e47';
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  drawNet();
  drawScore(canvas.width / 4 - 20, canvas.height / 10, `You: ${user.score}`);
  drawScore((3 * canvas.width) / 4 - 20, canvas.height / 10, `AI: ${ai.score}`);
  drawPaddle(user.x, user.y, user.width, user.height, user.color);
  drawPaddle(ai.x, ai.y, ai.width, ai.height, ai.color);
  drawBall(ball.x, ball.y, ball.radius, ball.color);
}

function updateScores() {
  const scoreboard = document.querySelector('.home-scoreboard__entries');

  // Get the top 5 scores from our leaderboard
  db.collection('scores')
    .orderBy('score', 'desc')
    .limit(5)
    .get()
    .then((snapshot) => {
      let rank = 1;

      snapshot.forEach((doc) => {
        scoreboard.innerHTML += `
          <li class="home-scoreboard__entry">
            <div class="home-scoreboard__column">
              ${rank++} ${doc.data().name}
            </div>

            <div class="home-scoreboard__column">
              ${doc.data().score}
            </div>
          </li>
        `;
      });
    });
}

// Home Landing
const landingStart = document.querySelector('.home-landing__start');
const landing = document.querySelector('.home-landing');
const game = document.querySelector('.home-game');

landingStart.addEventListener('click', () => {
  if (landing.classList.contains('home-landing')) {
    landing.classList.add('home-landing--is-hidden');
    updateScores();

    window.addEventListener(
      'keyup',
      () => {
        isPaused = false;
        pause.removeAttribute('disabled');
        document.body.classList.add('is-playing');
      },
      { once: true },
    );

    window.addEventListener(
      'keydown',
      () => {
        isPaused = false;
        pause.removeAttribute('disabled');
        document.body.classList.add('is-playing');
      },
      { once: true },
    );
  }

  if (game.classList.contains('home-game--hidden')) {
    game.classList.remove('home-game--hidden');
  }
});

// Submit Window
const pause = document.querySelector('.home-game__pause');
const popup = document.querySelector('.home-submit');
const close = document.querySelector('.home-submit__close');
const submitScore = document.querySelector('.home-submit__submit');
const input = document.querySelector('.home-submit__input input[type="text"]');
const gameContainer = document.querySelector('.home-game__container');
const score = document.querySelector('.home-submit__score');

function gameState(e) {
  if (e.target.innerHTML == 'Pause') {
    isPaused = true;
    pause.innerHTML = 'Unpause';
  } else {
    isPaused = false;
    pause.innerHTML = 'Pause';
  }
}

pause.addEventListener('click', gameState);

function publishScore() {
  let playerName = input.value;

  if (playerName == '') {
    alert('Please enter your name');
    return false;
  }

  db.collection('scores')
    .doc()
    .set({
      name: playerName,
      score: user.score,
    })
    .then(function () {
      document.location.reload();
    });
}

close.addEventListener('click', () => {
  if (popup.classList.contains('home-submit--is-visible')) {
    popup.classList.remove('home-submit--is-visible');
  }

  // Reload the game
  document.location.reload();
});

// Publish Score
submitScore.addEventListener('click', publishScore);

// Scoreboard
const scoreboard = document.querySelector('.home-scoreboard');
const openScoreboard = document.querySelector('.home-game__scoreboard');
const closeScoreboard = document.querySelector('.home-scoreboard__close');

openScoreboard.addEventListener('click', () => {
  scoreboard.classList.add('home-scoreboard--is-visible');

  if (document.body.classList.contains('is-playing')) {
    isPaused = true;
  }
});

closeScoreboard.addEventListener('click', () => {
  if (scoreboard.classList.contains('home-scoreboard--is-visible')) {
    scoreboard.classList.remove('home-scoreboard--is-visible');
  }

  if (document.body.classList.contains('is-playing')) {
    isPaused = false;
  } else {
    isPaused = true;
  }
});

function gameLoop() {
  if (!isPaused) {
    update();
    render();
  } else {
    render();
  }
}

const interval = setInterval(gameLoop, 1000 / 60);
